import { KeyBoardEvents } from '@aurora/shared-types/community/enums';
import type { FormatMessage } from '@aurora/shared-types/texts';
import type { ClassNamesFnWrapper } from 'react-bootstrap/lib/esm/createClassNames';
import type { AstNode, Editor, EditorEvent } from 'tinymce';
import { Actions, ForceBlocks, PastePlugin } from './TinyMceInternalHelper';
import { setCursorAtEndOfNode } from './EditorHelper';

const LI_SPOILER_ELEMENT = 'li-spoiler';
const LIA_SPOILER_ADD_COMMAND = 'liaSpoilerAdd';

const SPOILER_DATA_CONTAINER = 'data-lia-spoiler-container';
const SPOILER_DATA_CONTENT_WRAPPER = 'data-lia-spoiler-content-wrapper';
const SPOILER_DATA_CONTENT = 'data-lia-spoiler-content';
const SPOILER_DATA_LABEL_WRAPPER = 'data-lia-spoiler-label-wrapper';
const SPOILER_DATA_LABEL = 'data-lia-spoiler-label';

interface PastePostProcessEvent {
  /**
   * The node that will be inserted as part of the paste command.
   */
  node: HTMLElement;
}

/**
 * Whether the given element is a `Spoiler` element
 *
 * @param element Element to be checked
 */
const isSpoilerElement = (element: HTMLElement) =>
  element && element.nodeName === 'A' && element.classList.contains('lia-spoiler-link');

/**
 * Toggle open the spoiler content on click of the spoiler element.
 *
 * @param event Mouse event
 * @param spoilerElement Spoiler element
 */
function handleSpoilerClick(
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  spoilerElement: HTMLElement
): void {
  spoilerElement.closest('.lia-spoiler-container')?.classList.toggle('lia-spoiler-show');
  event.stopPropagation?.();
  event.preventDefault?.();
}

/**
 * Get the spoiler container, will check if the current element is the container or if
 * there is one that it is contained by. Will return `null` if not container is found.
 *
 * @param element the element
 */
function getSpoilerContainer(element: Element): Element | null {
  const selector = `[${SPOILER_DATA_CONTAINER}]`;
  return element.matches(selector) ? element : element.closest(selector);
}

/**
 * Register the spoiler button with TinyMce
 *
 * @param editor TinyMce editor
 * @param formatMessage Used to render the tooltip
 * @author Adam Ayres, Stephen McLaughry
 */
function registerSpoilerButton(editor: Editor, formatMessage: FormatMessage): void {
  editor.ui.registry.addToggleButton('liaSpoiler', {
    icon: 'warning',
    tooltip: formatMessage('spoilerTooltip'),
    onAction() {
      editor.execCommand(LIA_SPOILER_ADD_COMMAND);
    },
    onSetup(api) {
      return Actions.toggleState(editor, () => {
        api.setActive(
          !editor.mode.isReadOnly() && getSpoilerContainer(editor.selection.getNode()) !== null
        );
      });
    }
  });
}

/**
 * Create the HTML template used for displaying the Spoiler preview inside the TinyMce editor.
 * The cx/CSS-classes for this HTML are currently defined in `RichTextEditorField.module.pcss`
 *
 * @param cx instance of the className mapper from the containing React component.
 * @param formatMessage
 * @param titleValue the value to use in the spoiler title.
 * @param content the HTML content for the spoiler body.
 */
function createHtmlTemplate(
  cx: ClassNamesFnWrapper,
  formatMessage: FormatMessage,
  titleValue = '',
  content = ''
): string {
  const attrId = `lia-spoiler-${Date.now()}`;

  return `<div class="${cx(
    'lia-spoiler-editor'
  )}" contenteditable="false" ${SPOILER_DATA_CONTAINER}="${attrId}">
      <div contenteditable="true" ${SPOILER_DATA_LABEL_WRAPPER}>
        <input
          contenteditable="true"
          type="text"
          class="${cx('lia-spoiler-editor-title')}"
          placeholder="${formatMessage('spoilerLabelDefaultText')}"
          value="${titleValue}"
          ${SPOILER_DATA_LABEL}
        />
      </div>
      <div
        contenteditable="true"
        class="${cx('lia-spoiler-editor-content')}"
        ${SPOILER_DATA_CONTENT_WRAPPER}
      >
        <div contenteditable="true" ${SPOILER_DATA_CONTENT}>${content}</div>
      </div>
    </div>`;
}

/**
 * We do not use `editor.selection.setContent` or `editor.insertContent` (which just calls setContent)
 * because both of these TinyMce APIs to add content to the editor will strip out the
 * "bogus" `<br>` element used internally by TinyMce and instead replace it with: `<p>&nbsp;</p>`. We use
 * the bogus br element in our HTML template to add a placeholder without adding the whitespace - the same
 * as TinyMce does internally for this same use case.
 */
function addSpoiler(editor: Editor, cx, formatMessage: FormatMessage): void {
  const selectedNode = editor.selection.getNode();

  const spoilerTemplate = createHtmlTemplate(cx, formatMessage);
  const spoilerElement = editor.dom.create('div', null, spoilerTemplate).firstChild as Element;
  const spoilerContentElement = spoilerElement.querySelector(`[${SPOILER_DATA_CONTENT}]`);

  selectedNode.before(spoilerElement);
  spoilerContentElement.append(selectedNode);
}

function removeSpoiler(editor: Editor, spoilerElement: Element): void {
  const contentElement = spoilerElement.querySelector(`[${SPOILER_DATA_CONTENT}]`);
  spoilerElement.replaceWith(...contentElement.childNodes);
}

/**
 * Registers the `liaSpoilerAdd` command which adds a new spoiler to the editor.
 * If contents are selected they will be populated in the new spoiler
 * element.
 */
function registerAddSpoilerCommand(editor: Editor, cx, formatMessage: FormatMessage) {
  editor.addCommand(LIA_SPOILER_ADD_COMMAND, () => {
    const node = editor.selection.getNode();
    editor.undoManager.transact(() => {
      const rng = editor.selection.getRng();
      const { startContainer, startOffset, endContainer, endOffset } = rng;

      const spoiler = getSpoilerContainer(node);
      if (spoiler) {
        removeSpoiler(editor, spoiler);
      } else {
        addSpoiler(editor, cx, formatMessage);
      }

      // Set the cursor back to the original element selected
      rng.setStart(startContainer, startOffset);
      rng.setEnd(endContainer, endOffset);
      editor.selection.setRng(rng);
      editor.nodeChanged();
    });
  });
}

/**
 * Locates the backing Element of a Spoiler from a corresponding TinyMce `Node`.
 *
 * @param editor the TinyMce editor
 * @param node the node
 */
function getContainerElementFromNode(editor: Editor, node: AstNode): Element {
  const spoilerAttrValue = node.attr(SPOILER_DATA_CONTAINER);
  return editor.getBody().querySelector(`[${SPOILER_DATA_CONTAINER}=${spoilerAttrValue}]`);
}

/**
 * Registers a node filter that converts a `li-spoiler` XML into the HTML used
 * while editing a spoiler in the TinyMce editor.
 *
 * We initially attempted to use TinyMce's Node API to transform and replace
 * the nodes at the time the filter callback is hit. However, when applying the
 * node transformations via the Node API as part of the filter callback it apparently
 * did not attach TinyMce's noneditable plugin behavior which we are depending on
 * to prevent nested editable fields inside of a contenteditable that is set to false.
 *
 * This resulted in a bug described in LIA-73313. To work around this issue we instead
 * wait for the next ticket after the node filter has run, at which point the DOM in TinyMce has
 * been updated to include the <li-spoiler> element. We then use the native DOM API to update
 * the li-spoiler element with our custom markup for the spoiler editor control. This insures that
 * TinyMce's noneditable functionality is properly applied.
 *
 * @param editor the TinyMce editor
 * @param cx instance of the className mapper from the containing React component
 * @param formatMessage
 */
function registerXmlToHtmlConverter(
  editor: Editor,
  cx: ClassNamesFnWrapper,
  formatMessage: FormatMessage
): void {
  editor.parser.addNodeFilter(LI_SPOILER_ELEMENT, () => {
    setTimeout(() => {
      editor
        .getBody()
        .querySelectorAll(LI_SPOILER_ELEMENT)
        .forEach(element => {
          // eslint-disable-next-line no-param-reassign
          element.outerHTML = createHtmlTemplate(
            cx,
            formatMessage,
            element.getAttribute('label') ?? '',
            element.innerHTML
          );
        });
    });
  });
}

/**
 * Registers an attribute filter that converts the HTML used for displaying the
 * spoiler when in TinyMce editor to the backing XML used for storing the spoiler contents.
 * The registered attribute filter is only triggered when the contents of the editor are
 * serialized for sending the final value to the server (or for use by the container form).
 *
 * @param editor the TinyMceEditor
 */
function registerHtmlToXmlConverter(editor: Editor): void {
  // Watch for HTML elements that have the `data-lia-spoiler-container` data attribute
  editor.serializer.addAttributeFilter(SPOILER_DATA_CONTAINER, nodes => {
    nodes.forEach(node => {
      const [, childDiv] = node.getAll('div');

      // Check that the second child div has the `data-lia-spoiler-content` data attribute
      if (childDiv?.attr(SPOILER_DATA_CONTENT_WRAPPER) !== undefined) {
        // Construct the `li-spoiler` node
        const spoilerNode = new window.tinymce.html.Node(LI_SPOILER_ELEMENT, 1);

        // Locate the corresponding HTML Element for the current spoiler container `Node`.
        // We use the correspond Element in order to use vanilla DOM methods to get the
        // value of the input element for the label. This value is not available from the
        // TinyMce `Node` API.
        const containerElement = getContainerElementFromNode(editor, node);

        const labelValue = containerElement.querySelector<HTMLInputElement>(
          `[${SPOILER_DATA_LABEL}]`
        )?.value;

        if (labelValue?.trim()?.length > 0) {
          spoilerNode.attr('label', labelValue);
        }

        const spoilerContents = containerElement.querySelector<HTMLDivElement>(
          `[${SPOILER_DATA_CONTENT}]`
        );

        const spoilerContentsWrapper = containerElement.querySelector<HTMLDivElement>(
          `[${SPOILER_DATA_CONTENT_WRAPPER}]`
        );

        if (spoilerContents?.innerHTML?.length > 0) {
          const serializedContents = editor.serializer.serialize(spoilerContents);
          const spoilerNodeContents = editor.parser.parse(serializedContents).firstChild;
          spoilerNodeContents.getAll('a').forEach(aNode => {
            aNode.attr('data-mce-href', null);
          });
          // Append the spoiler contents to the new `li-spoiler` node
          spoilerNodeContents.children().forEach(childNode => {
            spoilerNode.append(childNode);
          });
        } else {
          spoilerContentsWrapper.innerHTML = `<div contenteditable="true" ${SPOILER_DATA_CONTENT}><p><br data-mce-bogus="1"></p></div>`;
        }

        // Replace the div with the `li-spoiler`
        node.replace(spoilerNode);
      }
    });
  });
}

/**
 * Inserts text into an input element at the current cursor location. If input is not
 * focused then the text is appended to the end of the input.
 *
 * @param inputElement the input element
 * @param text the text to insert
 */
function insertTextInInputAtCaret(inputElement: HTMLInputElement, text: string): void {
  if (inputElement.selectionStart || inputElement.selectionStart === 0) {
    const { scrollTop, selectionEnd, selectionStart } = inputElement;

    const { value: currentValue } = inputElement;

    inputElement.value =
      currentValue.slice(0, selectionStart) +
      text +
      currentValue.slice(selectionEnd, currentValue.length);

    inputElement.focus();
    inputElement.selectionStart = selectionStart + text.length;
    inputElement.selectionEnd = selectionStart + text.length;
    inputElement.scrollTop = scrollTop;
  } else {
    inputElement.value += text;
    inputElement.focus();
  }
}

/**
 * Creates an event handler function used when attempting to paste when the
 * spoiler label is focused. This corrects a behavior in TinyMce where it attempts
 * to paste the context before the selected input element as opposed to setting the value
 * in the input element.
 *
 * @param inputElement
 */
function handlePasteWrapper(
  inputElement: HTMLInputElement
): (event: EditorEvent<PastePostProcessEvent>) => void {
  return function handlePaste(event: EditorEvent<PastePostProcessEvent>) {
    event.preventDefault();
    const textToInsert = (event.node as HTMLElement).textContent;
    insertTextInInputAtCaret(inputElement, textToInsert);
  };
}

/**
 *
 * When the spoiler label field is focused, we need to add special handling for when a
 * paste command is triggered via the keyboard (ctrl-v). Otherwise, TinyMce's
 * internal support for pasting will add a peer-element next to the input with the pasted
 * content.
 *
 * @param event
 * @param editor
 */
function keydownHandler(event: EditorEvent<KeyboardEvent>, editor: Editor) {
  const element = event.target as HTMLInputElement;

  if (element?.matches(`[${SPOILER_DATA_LABEL}]`) && event.key === KeyBoardEvents.ENTER) {
    event.preventDefault();
    const spoilerBody: HTMLElement = element.parentElement.nextElementSibling.querySelector(
      `[${SPOILER_DATA_CONTENT}]`
    ) as HTMLElement;
    spoilerBody?.focus();
    if (spoilerBody.lastChild.textContent) {
      const pTag = document.createElement('p');
      pTag.innerHTML = '<br>';
      spoilerBody.append(pTag);
    }
    editor.selection.setCursorLocation(spoilerBody.lastChild, 0);
  }

  const nextElm = editor.selection.getNode().nextElementSibling as HTMLInputElement;
  const previousElm = editor.selection.getNode().previousElementSibling as HTMLInputElement;

  // if DOWN key is pressed from spoiler header, cursor should go to Spoiler body's first p tag
  if (element?.matches(`[${SPOILER_DATA_LABEL}]`) && event.key === KeyBoardEvents.ARROW_DOWN) {
    const spoilerBody: HTMLElement = element.parentElement.nextElementSibling.querySelector(
      `[${SPOILER_DATA_CONTENT}]`
    ) as HTMLElement;
    const firstParagraph = spoilerBody.querySelector('p');
    setTimeout(() => {
      setCursorAtEndOfNode(editor, firstParagraph);
    });
  }

  // if DOWN key is pressed and below element is a spoiler tag, cursor should go to Spoiler header
  if (nextElm?.matches(`[${SPOILER_DATA_CONTAINER}]`) && event.key === KeyBoardEvents.ARROW_DOWN) {
    const spoilerHeader: HTMLElement = nextElm.querySelector(
      `[${SPOILER_DATA_LABEL}]`
    ) as HTMLElement;
    setCursorAtEndOfNode(editor, spoilerHeader);
  }

  // if UP key is pressed and above element is a spoiler tag, cursor should go to Spoiler body's last p tag
  if (
    previousElm?.matches(`[${SPOILER_DATA_CONTAINER}]`) &&
    event.key === KeyBoardEvents.ARROW_UP
  ) {
    const spoilerBody: HTMLElement = previousElm.querySelector(
      `[${SPOILER_DATA_CONTENT}]`
    ) as HTMLElement;
    const lastParagraph = spoilerBody.lastChild as HTMLElement;
    setTimeout(() => {
      setCursorAtEndOfNode(editor, lastParagraph);
    });
  }

  // if UP key is pressed from spoiler header, cursor should go to the above element
  if (element?.matches(`[${SPOILER_DATA_LABEL}]`) && event.key === KeyBoardEvents.ARROW_UP) {
    let targetElement = editor.selection.getNode().parentElement.previousElementSibling;
    // if above element is also a spoiler tag
    if (targetElement?.matches(`[${SPOILER_DATA_CONTAINER}]`)) {
      const spoilerBody: HTMLElement = editor.selection
        .getNode()
        .parentElement.previousElementSibling.querySelector(
          `[${SPOILER_DATA_CONTENT}]`
        ) as HTMLElement;
      targetElement = spoilerBody.lastChild as HTMLElement;
    }
    setTimeout(() => {
      setCursorAtEndOfNode(editor, targetElement);
    });
  }

  if (
    PastePlugin.Clipboard.isKeyboardPasteEvent(event) &&
    element?.matches(`[${SPOILER_DATA_LABEL}]`)
  ) {
    const handlePaste = handlePasteWrapper(element);

    editor.once('PastePostProcess', handlePaste);

    editor.once('keyup', () => {
      editor.off('PastePostProcess', handlePaste);
    });
  }
}

/**
 * Whether there is a currently focused Spoiler.
 */
let spoilerHasFocusSingleton = false;
/**
 * A reference to the event handler for the currently focused spoiler
 * responsible for handling a paste action from the contextmenu. We store
 * this as a singleton so that we can turn off the event when the spoiler title
 * is blurred.
 */
let spoilerContextHandlerSingleton: (event: Event) => void;

/**
 * When the spoiler label field is focused, we need to add special handling for when a
 * paste command is triggered via the right-click contextmenu. Otherwise, TinyMce's
 * internal support for pasting will add a peer-element next to the input with the pasted
 * content.
 *
 * Also, we need to handle the use case where the contenteditable div that is wrapping the
 * input is inadvertently focused. This wrapping div sets contenteditable to true in order to
 * work around a TinyMce bug that otherwise prevents the input from being editable. In the case
 * the wrapping div is focused we instead shift focused to the nested input.
 *
 * @param editor the editor
 * @param toggleState whether to set the contentediable state to true or false
 */
function createFocusHandler(editor: Editor, toggleState: boolean): (event: FocusEvent) => void {
  return function focusHandler(event: FocusEvent): void {
    const element = event.target as HTMLElement;
    if (element?.matches(`[${SPOILER_DATA_LABEL}]`)) {
      const handlePaste = handlePasteWrapper(element as HTMLInputElement);

      if (toggleState === false) {
        // Set input value in editor dom on focus out
        editor.dom.setAttrib(element, 'value', (element as HTMLInputElement).value);
      }
      if (toggleState && !spoilerHasFocusSingleton) {
        spoilerHasFocusSingleton = true;
        spoilerContextHandlerSingleton = () => {
          editor.once('PastePostProcess', handlePaste);
        };
        editor.on('contextmenu', spoilerContextHandlerSingleton);
      } else {
        spoilerHasFocusSingleton = true;
        editor.off('contextmenu', spoilerContextHandlerSingleton);
      }
    } else if (toggleState && element?.matches(`[${SPOILER_DATA_LABEL_WRAPPER}]`)) {
      element.querySelector<HTMLInputElement>(`[${SPOILER_DATA_LABEL}]`).focus();
    } else if (spoilerHasFocusSingleton) {
      editor.off('contextmenu', spoilerContextHandlerSingleton);
      spoilerHasFocusSingleton = false;
      spoilerContextHandlerSingleton = null;
    }
  };
}

/**
 * The `forced_root_block` feature of TinyMce only works on the root block, not on content editable elements
 * that are inside of non content editable items. In the case of the spoiler we want the nested contenteditable,
 * that is inside of the wrapping non content editable, to behave similar to the root where force a `<p> element
 * to always be present. This prevents other unusual behaviors/bugs from occurring when using unordered lists,
 * blockquotes, and other features of TinyMce inside of the spoiler.
 */
function registerSpoilerForceRootBlock(editor: Editor): void {
  ForceBlocks.setup(editor, `[${SPOILER_DATA_CONTAINER}]`, `[${SPOILER_DATA_CONTENT}]`);
}

/**
 * The `end_container_on_empty_block` feature of TinyMce only works when not inside of a content editable.
 * In the case of the spoiler, we want to mimic the behavior where when a newline is used and the previous
 * container element was empty, we move the newline outside of the current block.
 *
 * @param editor
 */
function registerSpoilerNewBlock(editor: Editor): void {
  editor.on('NewBlock', ({ newBlock }) => {
    const spoilerContainer = getSpoilerContainer(newBlock);
    if (spoilerContainer && editor.dom.isEmpty(newBlock.previousSibling)) {
      newBlock.previousSibling.remove();
      spoilerContainer.after(newBlock);
      editor.selection.setCursorLocation(newBlock, 0);
    }
  });
}

export default function registerSpoiler(
  editor: Editor,
  cx: ClassNamesFnWrapper,
  formatMessage: FormatMessage
): void {
  registerAddSpoilerCommand(editor, cx, formatMessage);
  registerSpoilerButton(editor, formatMessage);
  registerSpoilerForceRootBlock(editor);
  registerSpoilerNewBlock(editor);

  editor.on('focusin', createFocusHandler(editor, true));
  editor.on('focusout', createFocusHandler(editor, false));

  editor.on('keydown', event => {
    keydownHandler(event, editor);
  });

  editor.on('preInit', () => {
    registerXmlToHtmlConverter(editor, cx, formatMessage);
    registerHtmlToXmlConverter(editor);
  });
}

export { isSpoilerElement, handleSpoilerClick };
